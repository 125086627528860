.policy {
  h1 {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.3rem !important;
  }

  p {
    margin-bottom: 1rem !important;
  }

  ul {
    margin-bottom: 1rem !important;
    padding-left: 1rem !important;
    list-style-type: circle !important;
  }

  li {
    padding-left: 0.5rem !important;
  }
}
