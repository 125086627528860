@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  background-color: #f6f6f6;
  color: #212427;
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-family: "Righteous", cursive; */
  /* font-family: "Barlow", sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: #5fbfff;
  --primary-accent: #4e616c;
  --focus: #f5cb71;
}

textarea:focus,
input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

/* Global classes */

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #0000ee;
}

.link:hover {
  text-decoration: underline;
}

.box-shadow {
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
}
