.pulse-primary {
  border-radius: 50%;
  background: var(--primary-accent);
  box-shadow: 0 0 0 var(--primary-accent);
  color: white;
  animation: pulse-primary 3s infinite;
}

@-webkit-keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--primary-accent);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-primary {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--primary-accent);
    box-shadow: 0 0 0 0 var(--primary-accent);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.pulse-focus {
  border-radius: 50%;
  background: var(--focus);
  box-shadow: 0 0 0 var(--focus);
  color: white;
  animation: pulse-focus 3s infinite;
}

@-webkit-keyframes pulse-focus {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--focus);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-focus {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--focus);
    box-shadow: 0 0 0 0 var(--focus);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
